import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { signInWithGoogle, auth } from '../common/firebase'
import { UserContext } from '../providers/UserProvider'

import logoTransparent from '../assets/images/logo_transparent.png'
import googleSignInButtonImg from '../assets/images/buttons/btn_google_signin_dark_normal_web.png'

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.lightest,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    paddingBottom: 200, // push the form dialog toward the center
  },

  logo: {
    height: theme.spacing(20),
  },

  dialog: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 250,
    maxWidth: 450,
    maxHeight: 350,
    padding: theme.spacing(4),
  },

  prompt: {
    display: 'flex',
    flex: 1,
    marginBottom: theme.spacing(2),
  },

  field: {
    display: 'flex',
    flex: 1,
    margin: theme.spacing(1),
    width: '100%',
  },

  button: {
    minWidth: theme.spacing(100 / 8),
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },

  googleButtonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },

  googleButton: {
    marginTop: '8px',
    cursor: 'pointer',
  },
}))

const Login = props => {
  const classes = useStyles(props)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const currentUser = useContext(UserContext)
  console.log('LOGIN > user:')
  console.log(currentUser)

  const history = useHistory()

  useEffect(() => {
    if (currentUser) {
      history.replace('/dashboard')
    }
  }, [currentUser, history])

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault()
    auth.signInWithEmailAndPassword(email, password).catch(error => {
      setError('Error signing in with password and email!')
      console.error('Error signing in with password and email', error)
    })
  }

  const handleChange = event => {
    const { name, value } = event.currentTarget

    if (name === 'userEmail') {
      setEmail(value)
    } else if (name === 'userPassword') {
      setPassword(value)
    }
  }

  return (
    <>
      <div className={classes.dialogContainer}>
        <a href="/">
          <img className={classes.logo} src={logoTransparent} alt="CodeChec Logo" />
        </a>

        <Paper className={classes.dialog}>
          <Typography variant="h6" className={classes.prompt}>
            Log In
          </Typography>

          {error && <div>{error}</div>}

          {/* TODO: Re-enable these fields later */}

          {/* Username */}
          <TextField
            className={classes.field}
            name="username"
            label="Email"
            type="email"
            autoComplete="email"
            variant="outlined"
            size="small"
            onChange={handleChange}
            disabled
          />

          {/* Password */}
          <TextField
            className={classes.field}
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
            size="small"
            onChange={handleChange}
            disabled
          />

          {/* Button Controls */}
          <div className={classes.controls}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              className={classes.button}
              // disabled={loginInProgress || !canLogin()}
              disabled
            >
              Sign Up
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              // disabled={loginInProgress || !canLogin()}
              disabled
              onClick={event => {
                signInWithEmailAndPasswordHandler(event, email, password)
              }}
            >
              Log In
            </Button>

            <div className={classes.googleButtonContainer}>
              <img
                className={classes.googleButton}
                src={googleSignInButtonImg}
                alt="google sign in"
                onClick={signInWithGoogle}
              />
            </div>
          </div>
        </Paper>
      </div>
    </>
  )
}

export default Login
