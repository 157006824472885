import React, { useState, useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import CloseIcon from '@material-ui/icons/Close'

import { UserContext } from '../../../../providers/UserProvider'

import { createFolder } from '../../../../common/api'

const useStyles = makeStyles(theme => ({
  newFolderModal: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minWidth: 393,
    minHeight: 181,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
  },

  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },

  modalTitle: {
    fontSize: 20,
  },

  textField: {
    fontSize: 13,
  },

  // Buttons
  controlButtons: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },

  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 80,
    maxHeight: 30,
  },
}))

const NewFolderModal = props => {
  const classes = useStyles(props)

  const { handleNewFolderClose } = props

  const [folderName, setFolderName] = useState('')

  const currentUser = useContext(UserContext) // must be here, not inside function

  const handleCreateFolder = async () => {
    console.log('NEW FOLDER MODAL > HANDLE CREATE FOLDER')
    await createFolder(currentUser, folderName)
    handleNewFolderClose()
  }

  const handleChange = event => {
    setFolderName(event.target.value)
  }

  return (
    <Paper className={classes.newFolderModal}>
      <div className={classes.modalHeader}>
        <Typography variant="h5" className={classes.modalTitle}>
          New folder
        </Typography>
        <IconButton onClick={handleNewFolderClose} size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <TextField
        variant="outlined"
        size="small"
        autoFocus
        value={folderName}
        placeholder="Untitled folder"
        onChange={handleChange}
        InputProps={{
          classes: {
            input: classes.textField,
          },
        }}
        onKeyPress={event => {
          // Submit on enter (TODO: blur everything after; don't focus the Add button)
          // https://github.com/mui-org/material-ui/issues/5393#issuecomment-304707345
          if (event.key === 'Enter') {
            handleCreateFolder()
            event.preventDefault()
          }
        }}
      />
      <div className={classes.controlButtons}>
        <Button
          className={classes.button}
          color="primary"
          size="small"
          onClick={handleNewFolderClose}
        >
          Cancel
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleCreateFolder}
        >
          Create
        </Button>
      </div>
    </Paper>
  )
}

export default NewFolderModal
