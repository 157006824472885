import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 250,
    padding: theme.spacing(4),
  },
}))

const Listing1 = props => {
  const classes = useStyles(props)

  return (
    <>
      <Paper className={classes.dialog}>
        <div>Yasuo Johnson</div>
        <div>Teemo Smith</div>
        <div>Jinx McFly</div>
        <div>Ezreal Doe</div>
      </Paper>
    </>
  )
}

export default Listing1
