import React, { useState, useEffect } from 'react'

import { auth, createUserProfileDocument } from '../common/firebase'

export const UserContext = React.createContext()

const UserProvider = props => {
  const { children } = props

  const [currentUser, setcurrentUser] = useState(null)
  const [pending, setPending] = useState(true)

  // TODO: Add comments on this effect
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth)
        userRef.onSnapshot(snapShot => {
          setcurrentUser({
            id: snapShot.id,
            ...snapShot.data(),
          })
        })
      } else {
        setcurrentUser(null)
      }
      setPending(false)
    })
    return () => unsubscribe()
  }, [])

  if (pending) {
    return <>pending...</>
  }

  return <UserContext.Provider value={currentUser}>{children}</UserContext.Provider>
}

export default UserProvider
