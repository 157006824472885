import Empty from './components/Empty'
import ListUsers from './components/ListUsers'
import ListUserAssignments from './components/ListUserAssignments'
import ListUserAssignmentSubmissions from './components/ListUserAssignmentSubmissions'

const INITIAL_KEY = 'List Users' // the key of the component to load initially

const COMPONENT_LIST = {
  Empty: {
    component: Empty,
  },
  'List Users': {
    component: ListUsers,
  },
  'List User Assignments': {
    component: ListUserAssignments,
  },
  'List User Assignment Submissions': {
    component: ListUserAssignmentSubmissions,
  },
}

export { COMPONENT_LIST, INITIAL_KEY }
