import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import DropZoneFileUpload from './DropZoneFileUpload'

const useStyles = makeStyles(theme => ({
  // paper: {
  //   position: 'absolute',
  //   width: 400,
  //   backgroundColor: theme.palette.background.paper,
  //   borderRadius: '5px',
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  //   left: '40%',
  //   top: '20%'
  // },
  fileName: {
    // width: '100%',
    marginBottom: '10px',
  },
  title: {
    textAlign: 'center',
  },
}))

const UploadForm = props => {
  const { assignment, setAssignment, handleFileChange } = props
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.title} variant="h4" gutterBottom>
        Create Assignment
      </Typography>
      <TextField
        className={classes.fileName}
        value={assignment}
        onChange={event => setAssignment(event.target.value)}
        label="Assignment Name"
        required
      />

      {/* TODO: On upload, the Dashboard file listing should auto update */}
      <DropZoneFileUpload handleFileChange={handleFileChange} assignment={assignment} />
    </>
  )
}

export default UploadForm
