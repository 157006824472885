import React, { useState, useEffect, useContext } from 'react'

import { Route, Redirect } from 'react-router-dom'

import RouteWrapper from './RouteWrapper'

import { UserContext } from '../providers/UserProvider'

const PrivateRoute = props => {
  const [authenticated, setAuthenticated] = useState(null)
  // const [user] = useState(useContext(UserContext))

  const currentUser = useContext(UserContext)
  console.log('PRIVATE ROUTE > currentUser:')
  console.log(currentUser)

  useEffect(() => {
    // TODO dispatch (load current user)
    // TODO setAuthenicated as it makes sense

    console.log('PRIVATE ROUTE > USER AUTH EFFECT: user:')
    console.log(currentUser)
    // const user = getUser()

    // if (currentUser !== null) { this should always run - marco edit
    setAuthenticated(!!currentUser) // if user is truthy, then true, else false
    //}
  }, [currentUser])

  const VerifyAccess = () => {
    if (authenticated) {
      console.log('PRIVATE ROUTE > VERIFY ACCESS > authenticated is TRUE')
      // return <Component currentUser={currentUser} {...props} />
      return <RouteWrapper currentUser={currentUser} {...props} />
    }
    if (authenticated === false) {
      console.log('PRIVATE ROUTE > VERIFY ACCESS > authenticated is FALSE')
      return <Redirect to={{ pathname: '/login' }} />
    }
  }

  const { component: Component, ...rest } = props
  return <Route {...rest} render={VerifyAccess} />
}

export default PrivateRoute
