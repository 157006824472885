import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Divider from '@material-ui/core/Divider'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import ScheduleIcon from '@material-ui/icons/Schedule'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StorageIcon from '@material-ui/icons/Storage'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'

import NewItemButton from './new-items/NewItemButton'

const useStyles = makeStyles(theme => ({
  leftMenu: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minWidth: 240,
  },

  newButton: {
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },

  newButtonText: {
    fontWeight: 500,
  },

  button: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontWeight: 'bold',
    textTransform: 'capitalize',
    borderRadius: theme.spacing(3), // circular ends styling
  },

  menuItem: {
    marginTop: theme.spacing(0.5),
  },

  menuItemText: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 13,
    fontWeight: 500,
  },

  listItemIcon: {
    minWidth: 48,
  },

  divider: {
    marginTop: theme.spacing(1),
  },
}))

const LeftMenu = props => {
  const classes = useStyles(props)

  const { handleNewAssignmentOpen, handleNewFolderClick } = props

  const handleNewAssignmentClick = () => {
    handleNewAssignmentOpen()
  }

  return (
    <div className={classes.leftMenu}>
      <div className={classes.newButton}>
        <NewItemButton
          handleNewAssignmentClick={handleNewAssignmentClick}
          handleNewFolderClick={handleNewFolderClick}
        />
      </div>
      <MenuList>
        <MenuItem className={classes.menuItem}>
          <ListItemIcon
            classes={{
              root: classes.listItemIcon,
            }}
          >
            <FileCopyOutlinedIcon />
          </ListItemIcon>
          <Typography className={classes.menuItemText}>My Drive</Typography>
        </MenuItem>

        <MenuItem className={classes.menuItem}>
          <ListItemIcon
            classes={{
              root: classes.listItemIcon,
            }}
          >
            <PeopleAltOutlinedIcon />
          </ListItemIcon>
          <Typography className={classes.menuItemText}>Shared with me</Typography>
        </MenuItem>

        <MenuItem className={classes.menuItem}>
          <ListItemIcon
            classes={{
              root: classes.listItemIcon,
            }}
          >
            <ScheduleIcon />
          </ListItemIcon>
          <Typography className={classes.menuItemText}>Recent</Typography>
        </MenuItem>

        <MenuItem className={classes.menuItem}>
          <ListItemIcon
            classes={{
              root: classes.listItemIcon,
            }}
          >
            <StarBorderIcon />
          </ListItemIcon>
          <Typography className={classes.menuItemText}>Starred</Typography>
        </MenuItem>

        <MenuItem className={classes.menuItem}>
          <ListItemIcon
            classes={{
              root: classes.listItemIcon,
            }}
          >
            <DeleteOutlinedIcon />
          </ListItemIcon>
          <Typography className={classes.menuItemText}>Trash</Typography>
        </MenuItem>

        <Divider className={classes.divider} orientation="horizontal" />

        <MenuItem className={classes.menuItem}>
          <ListItemIcon
            classes={{
              root: classes.listItemIcon,
            }}
          >
            <StorageIcon />
          </ListItemIcon>
          <Typography className={classes.menuItemText}>Storage</Typography>
        </MenuItem>
      </MenuList>
    </div>
  )
}

export default LeftMenu
