import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import moment from 'moment'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import DescriptionIcon from '@material-ui/icons/Description'
import FolderIcon from '@material-ui/icons/Folder'

const useStyles = makeStyles(theme => ({
  iconContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },

  folderIcon: {
    color: '#5F6368',
  },

  fileIcon: {
    color: '#4585F4',
  },

  // Body Cells
  headCellText: {
    color: theme.palette.grey[600],
    paddingTop: theme.spacing(0.5),
    fontSize: 13,
    fontWeight: 500,
  },

  bodyNameCell: {
    paddingRight: theme.spacing(4),
  },

  bodyNameCellText: {
    color: theme.palette.grey[700],
    paddingTop: theme.spacing(0.5),
    fontSize: 13,
    fontWeight: 500,
  },

  bodyCellText: {
    color: theme.palette.grey[700],
    paddingTop: theme.spacing(0.4),
    fontSize: 13,
  },
}))

const FileListingTableRows = props => {
  const classes = useStyles(props)
  const { docs } = props

  return docs?.map((item, index) => (
    <TableRow key={index}>
      <TableCell className={classes.tableCellIcon} padding="checkbox">
        <div className={classes.iconContainer}>
          {item.type === 'folder' ? (
            <FolderIcon className={classes.folderIcon} />
          ) : (
            <DescriptionIcon className={classes.fileIcon} />
          )}
        </div>
      </TableCell>
      <TableCell className={classes.bodyNameCell} component="th" padding="none">
        <Typography className={classes.bodyNameCellText} noWrap>
          {item.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.bodyCellText} noWrap>
          me
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.bodyCellText} noWrap>
          {moment(item?.createdAt?.toDate()).calendar()}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.bodyCellText} noWrap>
          -
        </Typography>
      </TableCell>
    </TableRow>
  ))
}

export default FileListingTableRows
