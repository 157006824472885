import React from 'react'
import { Route } from 'react-router-dom'

const RouteWrapper = props => {
  const { header: Header, content: Content, footer: Footer, layout: Layout, ...rest } = props

  return (
    <Route
      {...rest}
      render={props => <Layout header={Header} content={Content} footer={Footer} {...props} />}
    />
  )
}

export default RouteWrapper
