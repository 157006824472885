import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 250,
    padding: theme.spacing(4),
  },
}))

const ListUserAssignments = props => {
  const classes = useStyles(props)

  return (
    <>
      <Paper className={classes.dialog}>
        <div>Hello World</div>
        <div>Map Coloring</div>
        <div>Change Maker</div>
      </Paper>
    </>
  )
}

export default ListUserAssignments
