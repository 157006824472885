import React, { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const dropZone = {
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderStyle: 'dashed',
  borderWidth: '2px',
  borderRadius: '2px',
  borderColor: 'eee',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}
const useStyles = makeStyles(theme => ({
  container: {
    //   minWidth: '300px',
    //   minHeight: '300px',
  },
  button: {
    marginBottom: theme.spacing(1),
  },
}))

const DropZoneFileUpload = props => {
  const { handleFileChange, assignment } = props
  const classes = useStyles()

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone()

  const style = useMemo(
    () => ({
      ...dropZone,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  )

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <section className={classes.container}>
      <div className={classes.dropZone} {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      {files.length !== 0 ? (
        <aside>
          <h4>Files</h4>
          <ul>{files}</ul>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            disabled={!assignment}
            onClick={() => handleFileChange(acceptedFiles)}
          >
            upload
          </Button>
        </aside>
      ) : null}
    </section>
  )
}
export default DropZoneFileUpload
