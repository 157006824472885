import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 250,
    padding: theme.spacing(4)
  },

  newButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 600
  },

  newButton: {
    color: 'black',
    backgroundColor: '#e37054', // light red-orange
    fontWeight: 'bold',
    fontSize: 20,
    minWidth: 100,
    textTransform: 'none' // Title Case
  },

  row: {
    display: 'flex',
    flex: 1,
    marginTop: theme.spacing(2)
  },

  field: {
    display: 'flex',
    flex: 1,
    minWidth: 600
  },

  searchField: {
    backgroundColor: '#e2e2e2' // light gray
  },

  card: {
    minWidth: 600,
    maxHeight: 55,
    backgroundColor: '#aad5f2' // light blue
  },

  cardText: {
    fontWeight: 'bold'
  }
}))

const Whatever = props => {
  const classes = useStyles(props)

  return (
    <div className={classes.whatever}>
      <Paper className={classes.dialog}>
        <div className={classes.newButtonContainer}>
          <Button className={classes.newButton} variant="contained" color="secondary">
            Empty
          </Button>
        </div>
        <div className={classes.row}>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.cardText}>Even Cooler</Typography>
            </CardContent>
          </Card>
        </div>
      </Paper>
    </div>
  )
}

export default Whatever
