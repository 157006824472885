import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import logoTransparentLandscape from '../../assets/images/logo_transparent_landscape.png'

const useStyles = makeStyles(theme => ({
  logo: {
    height: 45,
  },
}))

const LogoLandscape = props => {
  const classes = useStyles(props)

  const { clickable } = props

  const renderImage = () => {
    return (
      <img
        className={classes.logo}
        src={logoTransparentLandscape}
        alt="codechec logo"
      />
    )
  }

  return <>{clickable ? <a href="/">{renderImage()}</a> : renderImage()}</>
}

export default LogoLandscape
