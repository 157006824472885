import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'

import LoginButton from '../common/components/LoginButton'
import LogoLandscape from '../common/components/LogoLandscape'

import stockLaptopTransImg from '../assets/images/stock-laptop-trans-large.png'

const MAX_HOMEPAGE_CONTENT_WIDTH = 1220

const useStyles = makeStyles(theme => ({
  homepageContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },

  homepage: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
  },

  // Alternating Color Homepage Bars
  homepageBar: {
    display: 'flex',
    flex: 1,
    width: '100%',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: 120,
    paddingBottom: 100,
  },

  lightBar: {
    display: 'flex',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
  },

  darkBar: {
    display: 'flex',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[100],
  },

  barContents: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    maxWidth: MAX_HOMEPAGE_CONTENT_WIDTH,
  },

  barContentsColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: MAX_HOMEPAGE_CONTENT_WIDTH,
  },

  // Laptop Bar
  firstBar: {
    paddingTop: 70, // TODO: fix this after position:fixed hack is fixed
  },

  imageContainer: {}, // required for fixed-ratio responsive images

  laptop: {
    width: '100%',
    height: 'auto',
  },

  subTagline: {
    marginTop: theme.spacing(3),
    fontSize: 15,
    color: theme.palette.grey[600],
  },

  loginButtonContainer: {
    marginTop: theme.spacing(3),
  },

  imageRight: {
    paddingLeft: theme.spacing(8),
  },

  // Second Bar
  imageLeft: {
    paddingRight: theme.spacing(8),
  },

  // How It Works Bar
  howItWorksTitle: {
    marginBottom: 100,
  },

  imageRow: {
    display: 'flex',
    flex: 1,
    width: '100%',
    justifyContent: 'space-between',
  },

  howItWorksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  laptopSmallContainer: {
    padding: theme.spacing(4),
  },

  laptopSmall: {
    maxWidth: 300, // actual max width
    width: '100%', // responsive width
    height: 'auto', // responsive height
  },

  howStepSubtitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold', // TODO: soleil bold?
  },

  howStepText: {
    width: 245,
    marginTop: theme.spacing(4),
    lineHeight: 2,
    textAlign: 'center',
  },

  // Homepage Footer
  homepageFooter: {
    backgroundColor: '#f8f9fa',
    fontWeight: 'bold',
    alignItems: 'center',
  },

  footerLogo: {
    marginBottom: theme.spacing(1.5), // line up bottom of logo with links
  },

  lastBarLinks: {
    display: 'flex',
    marginLeft: theme.spacing(3),
  },

  bottomLink: {
    display: 'flex',
    marginLeft: theme.spacing(4),
  },
}))

const Homepage = props => {
  const classes = useStyles(props)

  // const currentUser = useContext(UserContext)

  return (
    <div className={classes.homepageContainer}>
      <div className={classes.homepage}>
        {/* Bar 1 */}
        <div className={clsx(classes.homepageBar, classes.lightBar, classes.firstBar)}>
          <div className={classes.barContents}>
            <div>
              <Typography variant="h3">
                Easy and secure code similarity detection for computer scientists
              </Typography>
              <Typography className={classes.subTagline}>
                Compare coding assignments instantly in all languages with our proprietary G.O.A.T.
                comparison algorithm
              </Typography>
              <div className={classes.loginButtonContainer}>
                <LoginButton />
              </div>
            </div>
            <div className={clsx(classes.imageContainer, classes.imageRight)}>
              <img className={classes.laptop} src={stockLaptopTransImg} alt="codechec logo" />
            </div>
          </div>
        </div>

        {/* Bar 2 */}
        <div className={clsx(classes.homepageBar, classes.darkBar, classes.secondBar)}>
          <div className={classes.barContents}>
            <div className={clsx(classes.imageContainer, classes.imageLeft)}>
              <img className={classes.laptop} src={stockLaptopTransImg} alt="codechec logo" />
            </div>
            <div>
              <Typography variant="h3">
                Easy and secure code similarity detection for coding instructors
              </Typography>
              <Typography className={classes.subTagline}>
                Compare coding assignments instantly in all languages with our proprietary G.O.A.T.
                comparison algorithm
              </Typography>
              <div className={classes.loginButtonContainer}>
                <LoginButton />
              </div>
            </div>
          </div>
        </div>

        {/* Bar 3 */}
        <div className={clsx(classes.homepageBar, classes.lightBar, classes.secondBar)}>
          <div className={classes.barContents}>
            <div>
              <Typography variant="h3">
                Easy and secure similarity detection for coding assignments
              </Typography>
              <Typography className={classes.subTagline}>
                Compare coding assignments instantly in all languages with our proprietary G.O.A.T.
                comparison algorithm
              </Typography>
              <div className={classes.loginButtonContainer}>
                <LoginButton />
              </div>
            </div>
            <div className={clsx(classes.imageContainer, classes.imageRight)}>
              <img className={classes.laptop} src={stockLaptopTransImg} alt="codechec logo" />
            </div>
          </div>
        </div>

        {/* Bar 4 - How It Works */}
        <div className={clsx(classes.homepageBar, classes.darkBar, classes.secondBar)}>
          <div className={classes.barContentsColumn}>
            <Typography className={classes.howItWorksTitle} variant="h3">
              How It Works
            </Typography>
            <div className={clsx(classes.imageRow)}>
              <div className={classes.howItWorksContainer}>
                <div className={classes.laptopSmallContainer}>
                  <img
                    className={classes.laptopSmall}
                    src={stockLaptopTransImg}
                    alt="codechec logo"
                  />
                </div>
                <Typography variant="h6" className={classes.howStepSubtitle}>
                  Sign Up
                </Typography>
                <Typography variant="body1" className={classes.howStepText}>
                  Create your free account and create a new assignment.
                </Typography>
              </div>
              <div className={classes.howItWorksContainer}>
                <div className={classes.laptopSmallContainer}>
                  <img
                    className={classes.laptopSmall}
                    src={stockLaptopTransImg}
                    alt="codechec logo"
                  />
                </div>
                <Typography variant="h6" className={classes.howStepSubtitle}>
                  Upload Code
                </Typography>
                <Typography variant="body1" className={classes.howStepText}>
                  Drag and drop upload student assignments all at one time.
                </Typography>
              </div>
              <div className={classes.howItWorksContainer}>
                <div className={classes.laptopSmallContainer}>
                  <img
                    className={classes.laptopSmall}
                    src={stockLaptopTransImg}
                    alt="codechec logo"
                  />
                </div>
                <Typography variant="h6" className={classes.howStepSubtitle}>
                  COMPARE
                </Typography>
                <Typography variant="body1" className={classes.howStepText}>
                  Run the comparison of all submissions against each other and view the results
                  instantly!
                </Typography>
              </div>
            </div>
          </div>
        </div>

        {/* Bar 5 - Popular Features */}
        <div className={clsx(classes.homepageBar, classes.lightBar, classes.secondBar)}>
          <div className={classes.barContentsColumn}>
            <Typography className={classes.howItWorksTitle} variant="h3">
              Popular Features
            </Typography>
            <div className={clsx(classes.imageRow)}>
              <div className={classes.howItWorksContainer}>
                <div className={classes.laptopSmallContainer}>
                  <img
                    className={classes.laptopSmall}
                    src={stockLaptopTransImg}
                    alt="codechec logo"
                  />
                </div>
                <Typography variant="h6" className={classes.howStepSubtitle}>
                  Sign Up
                </Typography>
                <Typography variant="body1" className={classes.howStepText}>
                  Create your free account and create a new assignment.
                </Typography>
              </div>
              <div className={classes.howItWorksContainer}>
                <div className={classes.laptopSmallContainer}>
                  <img
                    className={classes.laptopSmall}
                    src={stockLaptopTransImg}
                    alt="codechec logo"
                  />
                </div>
                <Typography variant="h6" className={classes.howStepSubtitle}>
                  Upload Code
                </Typography>
                <Typography variant="body1" className={classes.howStepText}>
                  Drag and drop upload student assignments all at one time.
                </Typography>
              </div>
              <div className={classes.howItWorksContainer}>
                <div className={classes.laptopSmallContainer}>
                  <img
                    className={classes.laptopSmall}
                    src={stockLaptopTransImg}
                    alt="codechec logo"
                  />
                </div>
                <Typography variant="h6" className={classes.howStepSubtitle}>
                  COMPARE
                </Typography>
                <Typography variant="body1" className={classes.howStepText}>
                  Run the comparison of all submissions against each other and view the results
                  instantly!
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Homepage Footer */}
      <div className={clsx(classes.homepageBar, classes.homepageFooter)}>
        <LogoLandscape
          classes={{
            logo: classes.footerLogo, // override the component CSS internals
          }}
        />
        <div className={classes.lastBarLinks}>
          <Typography className={classes.bottomLink}>Privacy</Typography>
          <Typography className={classes.bottomLink}>Terms</Typography>
          <Typography className={classes.bottomLink}>About</Typography>
        </div>
      </div>
    </div>
  )
}

export default Homepage
