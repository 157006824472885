import React from 'react'
import { NotificationContainer } from 'react-notifications'

import { BrowserRouter as Router, Switch } from 'react-router-dom'

// https://material-ui.com/styles/advanced/#theming
import { ThemeProvider } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import { Helmet } from 'react-helmet'

import { TITLE } from './common/constants'

import Header from './common/components/Header'
import Footer from './common/components/Footer'

import DatastoreIndex from './demos/datastore/DatastoreIndex'
import DesignIndex from './demos/designs/DesignIndex'

import EmptyLayout from './layouts/EmptyLayout'
import HeaderContentLayout from './layouts/HeaderContentLayout'
import HeaderContentFooterLayout from './layouts/HeaderContentFooterLayout'

import Dashboard from './pages/dashboard/Dashboard'
import Homepage from './pages/Homepage'
import Login from './pages/Login'
import Team from './pages/Team'
import Terms from './pages/Terms'

import UserProvider from './providers/UserProvider'

import PrivateRoute from './routes/PrivateRoute'
import RouteWrapper from './routes/RouteWrapper'

import theme from './themes/default'

import favIconTrans from './assets/favicon-trans.ico'

const useStyles = makeStyles(theme => ({
  app: {
    width: '100vw',
    height: '100vh',
    padding: 0,
    margin: 0,
    backgroundColor: theme.palette.common.white, // switch to black for debugging
  },
}))

const App = props => {
  const classes = useStyles(props)
  return (
    <div className={classes.app}>
      {/* Dynamic Header Info: https://github.com/nfl/react-helmet */}
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content={TITLE} />
        <link rel="icon" type="image/png" href={favIconTrans} />

        {/* Open Sans for titling and important buttons */}
        {/* https://fonts.google.com/specimen/Open+Sans */}
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"
          rel="stylesheet"
        />

        {/* Roboto for content text and normal links */}
        {/* https://fonts.google.com/specimen/Roboto */}
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <UserProvider>
        <ThemeProvider theme={theme}>
          {/* https://material-ui.com/components/css-baseline/ */}
          <CssBaseline />
          <Router>
            <Switch>
              <RouteWrapper
                exact
                path="/"
                header={Header}
                content={Homepage}
                layout={HeaderContentLayout}
              />
              <PrivateRoute exact path="/dashboard" content={Dashboard} layout={EmptyLayout} />
              <RouteWrapper path="/datastore" content={DatastoreIndex} layout={EmptyLayout} />
              <RouteWrapper path="/designs" content={DesignIndex} layout={EmptyLayout} />
              <RouteWrapper exact path="/team" content={Team} layout={EmptyLayout} />
              <RouteWrapper path="/login" content={Login} layout={EmptyLayout} />
              <RouteWrapper
                exact
                path="/terms"
                header={Header}
                content={Terms}
                footer={Footer}
                layout={HeaderContentFooterLayout}
              />
            </Switch>
          </Router>
        </ThemeProvider>
      </UserProvider>
      <NotificationContainer />
    </div>
  )
}

export default App
