import { firestore } from './firebase'

const createAssignment = async (currentUser, assignmentName) => {
  const createdAt = new Date()

  // TODO: Refactor this userKey into a constant
  const userKey = `users/${currentUser.id}`
  await firestore.doc(userKey).collection(`drive`).doc(assignmentName).set({
    name: assignmentName,
    type: 'assignment',
    createdAt,
  })
}

// TODO: Include a given path (array of folder Ids)
const createFolder = async (currentUser, folderName) => {
  console.log('API > CREATE FOLDER > currentUser:')
  console.log(currentUser)
  const createdAt = new Date()
  const userKey = `users/${currentUser.id}`
  const collection = 'drive'
  //   const docId = firestore.doc(userKey).collection(collection).doc().id

  try {
    await firestore.doc(userKey).collection(collection).doc(folderName).set({
      //   id: docId,
      name: folderName,
      type: 'folder',
      createdAt,
    })
  } catch (err) {
    console.log('CREATE FOLDER ERROR:')
    console.log(err)
  }
}

// TODO: Given a path of folders (array) and an assignment name
//       ... attempt to create the assignment at the given point in the tree

// TODO: Upon creating a new folder/assignment, make the UI auto update
// https://medium.com/javascript-in-plain-english/firebase-firestore-database-realtime-updates-with-react-hooks-useeffect-346c1e154219

export { createAssignment, createFolder }
