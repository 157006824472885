import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

import IconButton from '@material-ui/core/IconButton'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import GridOnIcon from '@material-ui/icons/GridOn'
import GroupIcon from '@material-ui/icons/Group'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    color: theme.palette.grey[600],
  },

  // Path Stuff
  path: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },

  pathToken: {
    padding: theme.spacing(1),
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontSize: 18,
    fontWeight: 400,
  },

  // Control Buttons
  controlButtons: {
    display: 'flex', // disables wrapping by default
  },
}))

const Breadcrumb = props => {
  const classes = useStyles(props)

  return (
    <div className={classes.breadcrumb}>
      <div className={classes.path}>
        <Typography className={classes.pathToken} noWrap>
          My Drive
        </Typography>
        <NavigateNextIcon className={classes.rightArrowIcon} />
        <Typography className={classes.pathToken} noWrap>
          CMSI 186
        </Typography>
        <IconButton className={classes.dropDownArrowIcon} aria-label="more">
          <ArrowDropDownIcon />
        </IconButton>
        <IconButton>
          <GroupIcon fontSize="small" />
        </IconButton>
      </div>
      <div className={classes.controlButtons}>
        <IconButton>
          <GridOnIcon />
        </IconButton>
        <IconButton>
          <InfoOutlinedIcon />
        </IconButton>
      </div>
    </div>
  )
}

export default Breadcrumb
