import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar'
import FormGroup from '@material-ui/core/FormGroup'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'

import logoSrc from '../../../assets/images/logo_transparent.png'
const username = 'test'
const email = 'test@lmu.edu'
const organization = 'Loyola Marymount University'
const bio = 'I am a test professor.'

const useStyles = makeStyles(theme => ({
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    width: 600,
    height: 400,
  },
  pictureContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
  },
  picture: {
    backgroundColor: 'gray',
    height: 100,
    width: 100,
  },
  userInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  username: {},
}))

const Profile = props => {
  const classes = useStyles(props)
  console.log(classes)

  return (
    <Paper>
      <FormGroup className={classes.profileContainer}>
        <div className={classes.pictureContainer}>
          <Avatar className={classes.picture} src={logoSrc}></Avatar>
        </div>
        <List className={classes.userInfoContainer}>
          <ListItem>Username: {username}</ListItem>
          <ListItem>Email: {email}</ListItem>
          <ListItem>Organization: {organization}</ListItem>
          <ListItem>Bio: {bio}</ListItem>
        </List>
      </FormGroup>
    </Paper>
  )
}

export default Profile
