import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Button } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import { withStyles } from '@material-ui/core/styles'

const NextButton = withStyles({
  root: {
    background: 'rgb(103, 117, 189)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    // height: 36,
    '&:hover': {
      background: 'rgb(63, 77, 149)',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

const SignInButton = withStyles({
  root: {
    background: 'white',
    borderRadius: 3,
    border: 0,
    color: 'rgba(63, 77, 149, 1)',
    height: 48,
    // padding: '0 30px',
    // height: 36,
    '&:hover': {
      background: 'rgba(63, 77, 149, 0.05)',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

const LanguageButton = withStyles({
  root: {
    // background: 'white',
    borderRadius: 3,
    border: 0,
    color: '#000',
    height: 32,
    padding: 0,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.05)',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

const MenuButton = withStyles({
  root: {
    borderRadius: 3,
    border: 0,
    color: '#000',
    height: 32,
    padding: 0,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.05)',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

const useStyles = makeStyles(theme => ({
  createAccountWarren: {
    minHeight: 558,
    minWidth: 748,
    maxHeight: 558,
    maxWidth: 748,
    display: 'flex',
    flexDirection: 'column',
  },

  paper: {
    flex: 1,
    display: 'flex',
    padding: '48px 40px 36px',
  },

  leftPanel: {
    // backgroundColor: 'blue',
    minWidth: 464,
  },

  rightPanel: {
    // backgroundColor: 'red',
    flex: 1,
  },

  logoContainer: {
    maxWidth: 368,
  },

  logo: {
    height: 'auto',
    width: '36%',
  },

  headerContainer: {
    minHeight: 48,
    paddingTop: 16,
  },

  subHeaderContainer: {
    minHeight: 32,
    paddingTop: 8,
  },

  formContainer: {
    minHeight: 370,
    paddingTop: 24,
  },

  headerText: {
    fontSize: 24,
    fontWeight: 600,
  },

  form1: {
    minHeight: 60,
    maxWidth: 368,
    display: 'flex',
    justifyContent: 'space-between',
  },

  textInput: {
    maxWidth: 174,
    margin: '8px 0px 4px',
    fontSize: 14,
    marginBotton: 8,
    padding: 0,
  },

  textInputEmail: {
    minWidth: 368,
    maxWidth: 368,
    margin: '8px 0px 4px',
    fontSize: 14,
    marginBotton: 8,
  },

  textSub: {
    fontSize: 12,
    minHeight: 24,
    maxHeight: 24,
  },

  form2: {
    minHeight: 60,
    flex: 1,
    maxWidth: 368,
    // padding: '8px 0px 8px',
  },

  alternateButton: {
    padding: 0,
    color: 'rgb(103, 117, 189)',
    '&:hover': {
      backgroundColor: 'rgba(103, 117, 189, 0.2)',
    },
  },

  alternateText: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'none',
  },

  form3: {
    minWidth: 320,
    maxWidth: 320,
    minHeight: 110,
    maxHeight: 110,
  },

  textInput2: {
    minWidth: 152,
    maxWidth: 152,
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 320,
    maxWidth: 320,
  },

  textFieldBotton: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 320,
    maxWidth: 320,
    padding: '24px 0px 8px',
  },

  iconButton: {
    paddingTop: 16,
  },

  form4: {
    maxWidth: 368,
    minWidth: 368,
    maxHeight: 48,
    minHeight: 48,
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 32,
  },

  nextButton: {
    height: 36,
    background: 'rgb(103, 117, 189)',
  },

  nextText: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white',
  },

  footer: {
    minWidth: 748,
    maxWidth: 748,
    paddingTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const CreateAccountWarren = props => {
  const classes = useStyles(props)
  console.log(classes)

  return (
    <div className={classes.createAccountWarren}>
      <Paper className={classes.paper}>
        <div className={classes.leftPanel}>
          <div className={classes.logoContainer}>
            <img className={classes.logo} src="/images/logo_transparent_landscape.png" alt="logo" />
          </div>
          <div className={classes.headerContainer}>
            <Typography variant="h1" className={classes.headerText}>
              Create your CodeChec Account
            </Typography>
          </div>
          <div className={classes.subHeaderContainer}>
            <Typography>to continue with CodeChec Dashboard</Typography>
          </div>
          <div className={classes.formContainer}>
            <div className={classes.form1}>
              <TextField
                className={classes.textInput}
                id="outlined-basic"
                label="First name"
                variant="outlined"
                size="small"
              />
              <TextField
                className={classes.textInput}
                id="outlined-basic"
                label="Last name"
                variant="outlined"
                size="small"
              />
            </div>
            <div>
              <div className={classes.form2}>
                <TextField
                  className={classes.textInputEmail}
                  id="outlined-basic"
                  label="Username"
                  variant="outlined"
                  size="small"
                />
                <Typography className={classes.textSub}>
                  You can use letters, numbers & periods
                </Typography>
              </div>
              <Button className={classes.alternateButton} disableRipple>
                <Typography className={classes.alternateText}>
                  Use my current email address instead
                </Typography>
              </Button>
            </div>
            <div className={classes.row}>
              <div className={classes.form3}>
                <div className={classes.textFieldBotton}>
                  <TextField
                    className={classes.textInput2}
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    className={classes.textInput2}
                    id="outlined-basic"
                    label="Confirm"
                    variant="outlined"
                    size="small"
                  />
                </div>
                <Typography className={classes.textSub}>
                  Use 8 or more characters with a mix of letters, numbers & symbols
                </Typography>
              </div>
              <div className={classes.iconButton}>
                <IconButton aria-label="toggle password visibility">
                  <Visibility />
                </IconButton>
              </div>
            </div>
            <div className={classes.form4}>
              <SignInButton disableRipple>
                <Typography className={classes.alternateText}>Sign in instead</Typography>
              </SignInButton>
              <NextButton variant="contained" disableRipple>
                <Typography className={classes.nextText}>Next</Typography>
              </NextButton>
            </div>
          </div>
        </div>
        <div className={classes.rightPanel}>right panel</div>
      </Paper>
      <div className={classes.footer}>
        <LanguageButton>English (United States)</LanguageButton>
        <div>
          <MenuButton>Help</MenuButton>
          <MenuButton>Privacy</MenuButton>
          <MenuButton>Terms</MenuButton>
        </div>
      </div>
    </div>
  )
}

export default CreateAccountWarren
