import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  empty: {},
}))

const Empty = props => {
  const classes = useStyles(props)
  console.log(classes)

  return <>EMPTY</>
}

export default Empty
