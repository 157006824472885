import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'

import FileListingTableRows from './FileListingTableRows'
import { firestore } from '../../../../common/firebase'
import { UserContext } from '../../../../providers/UserProvider'

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function createData(name, owner, lastModified, fileSize) {
  return { name, owner, lastModified, fileSize }
}

const rows = [
  createData('Cupcake', 305, 3.7, '-'),
  createData('Donut', 452, 25.0, '-'),
  createData('Eclair', 262, 16.0, '-'),
  createData('Frozen yoghurt', 159, 6.0, '-'),
  createData('Gingerbread', 356, 16.0, '-'),
  createData('Honeycomb', 408, 3.2, '-'),
  createData('Ice cream sandwich', 237, 9.0, '-'),
  createData('Jelly Bean', 375, 0.0, '-'),
  createData('KitKat', 518, 26.0, '-'),
  createData('Lollipop', 392, 0.2, '-'),
  createData('Marshmallow', 318, 0, '-'),
  createData('Nougat', 360, 19.0, '-'),
  createData('Oreo 1', 437, 18.0, '-'),
  createData('Oreo 2', 437, 18.0, '-'),
  createData('Oreo 3', 437, 18.0, '-'),
]

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'owner', numeric: false, disablePadding: false, label: 'Owner' },
  { id: 'lastModified', numeric: false, disablePadding: false, label: 'Last modified' },
  { id: 'fileSize', numeric: false, disablePadding: false, label: 'File size' },
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          const headCellClass = `headCell${capitalizeFirstLetter(headCell.id)}`
          const labelClass = `label${capitalizeFirstLetter(headCell.id)}`
          return (
            <TableCell
              className={classes[headCellClass]}
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              colSpan={index === 0 ? 2 : 1}
            >
              <TableSortLabel
                className={classes[labelClass]}
                classes={{
                  root: classes[labelClass],
                  active: classes[labelClass],
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography className={classes.headCellText} noWrap>
                  {headCell.label}
                </Typography>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // width: '100%',
  },

  tableContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(8),
  },

  table: {
    // minWidth: 750,
    // maxWidth: '400px',
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  // Table Row Icons
  tableCellIcon: {
    // display: 'flex',
    // flex: 1,
    // alignItems: 'center',
  },

  iconContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },

  folderIcon: {
    color: '#5F6368',
  },

  fileIcon: {
    color: '#4585F4',
  },

  // Table Head Cells
  headCellOwner: {
    width: 215,
  },

  headCellLastModified: {
    width: 200,
  },

  headCellFileSize: {
    width: 140,
  },

  labelOwner: {
    color: 'red',
  },

  // Body Cells
  headCellText: {
    color: theme.palette.grey[600],
    paddingTop: theme.spacing(0.5),
    fontSize: 13,
    fontWeight: 500,
  },

  bodyNameCell: {
    paddingRight: theme.spacing(4),
  },

  bodyNameCellText: {
    color: theme.palette.grey[700],
    paddingTop: theme.spacing(0.5),
    fontSize: 13,
    fontWeight: 500,
  },

  bodyCellText: {
    color: theme.palette.grey[700],
    paddingTop: theme.spacing(0.4),
    fontSize: 13,
  },
}))

const FileListing = props => {
  const classes = useStyles(props)

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('owner')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const [currentFolders, setCurrentFolders] = useState([])
  const [currentAssignments, setCurrentAssignments] = useState([])

  const currentUser = useContext(UserContext) // must be here, not inside function

  console.log('FILE LISTING > currentUser:')
  console.log(currentUser)

  // TODO: Refactor into a custom hook?
  useEffect(() => {
    const userKey = `users/${currentUser.id}`

    const unsubscribe = firestore
      .doc(userKey)
      .collection('drive')
      .onSnapshot(snapshot => {
        console.log('FILE LISTING > FIRESTORE EFFECT > snapshot:')
        console.log(snapshot)

        if (snapshot.size) {
          // we have something
          // ** Handle returned data **

          const folders = []
          const assignments = []

          snapshot.forEach(doc => {
            console.log('FILE LISTING > FIRESTORE EFFECT > snapshot doc:')
            console.log({ ...doc.data() })

            switch (doc?.data()?.type) {
              case 'folder':
                folders.push({ ...doc.data() })
                break

              default:
                assignments.push({ ...doc.data() })
            }
          })

          setCurrentFolders(folders)
          setCurrentAssignments(assignments)
        } else {
          // it's empty
          setCurrentFolders([])
          setCurrentAssignments([])
        }
      })
    return () => {
      unsubscribe()
    }
  }, [currentUser.email])

  // TODO: Re-do searching of the Drive
  // useEffect(() => {
  //   async function fetchAssignments() {
  //     const usersRef = firestore.doc(`users/${currentUser.id}`)
  //     const userAssignmentsRef = usersRef.collection(`assignments`)

  //     try {
  //       const userAssignmentsDoc = await userAssignmentsRef.get()
  //       console.log(userAssignmentsDoc)
  //       const data = userAssignmentsDoc.docs.map(collectIdsAndDocs)
  //       console.log('this is data: ', data)
  //       let re = new RegExp(searchTerm, 'i')
  //       setAssignments(
  //         data.filter(assignment => {
  //           return assignment.id.match(re)
  //         })
  //       )
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   if (currentUser) {
  //     fetchAssignments()
  //   }
  // }, [currentUser, searchTerm])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDense = event => {
    setDense(event.target.checked)
  }

  return (
    <div className={classes.fileListing}>
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            <FileListingTableRows docs={currentFolders} />
            <FileListingTableRows docs={currentAssignments} />
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Area (TODO: remove later) */}
      <TableContainer>
        <Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 25, 50]}
                // component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {/* Controls Area (TODO: removable later) */}
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {currentUser?.displayName}
      </Typography>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  )
}

export default FileListing
