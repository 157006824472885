import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import SearchIcon from '@material-ui/icons/Search'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import OfflinePinOutlinedIcon from '@material-ui/icons/OfflinePinOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'

const useStyles = makeStyles(theme => ({
  searchArea: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    // justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  icons: {
    color: theme.palette.grey[600],
  },

  // Form CSS
  searchForm: {
    display: 'flex',
    flex: 2, // give search form most priority
    paddingRight: theme.spacing(4),
    // paddingLeft: theme.spacing(1),
    minWidth: 520,
    maxWidth: 720,
  },

  searchContainer: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#F1F3F4',
    borderRadius: theme.spacing(1),
  },

  inputBase: {
    display: 'flex',
    flex: 1,
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontSize: 16,
    fontWeight: 400,
    color: 'black', // TODO: doesn't really look black
  },

  // Control Area
  topRightControlArea: {
    display: 'flex',
    flex: 1,
  },

  controlButtons: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 30,
  },
}))

const SearchBar = props => {
  const classes = useStyles(props)

  const { currentUser, setSearchTerm, photoURL } = props

  const [term, setTerm] = useState('')

  const handleSubmit = event => {
    event.preventDefault()
    setSearchTerm(term)
    setTerm('')
  }

  return (
    <div className={classes.searchArea}>
      <form onSubmit={handleSubmit} className={classes.searchForm} noValidate autoComplete="off">
        <div className={classes.searchContainer}>
          <IconButton className={classes.iconButton} aria-label="menu">
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.inputBase}
            placeholder="Search in CodeChec"
            inputProps={{ 'aria-label': 'search codechec' }}
            value={term}
            onChange={event => setTerm(event.target.value)}
          />
          <IconButton type="submit" className={classes.iconButton} aria-label="search">
            <ArrowDropDownIcon />
          </IconButton>
        </div>
      </form>
      <div className={classes.topRightControlArea}>
        <div className={classes.controlButtons}>
          <IconButton>
            <OfflinePinOutlinedIcon className={classes.icons} />
          </IconButton>
          <IconButton>
            <HelpOutlineOutlinedIcon className={classes.icons} />
          </IconButton>
          <IconButton>
            <SettingsOutlinedIcon className={classes.icons} />
          </IconButton>
        </div>
        <div className={classes.avatarContainer}>
          <Avatar className={classes.avatar} alt={currentUser?.displayName} src={photoURL} />
        </div>
      </div>
    </div>
  )
}

export default SearchBar
