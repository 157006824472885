/**
 * TODO: Each design demo should get a unique URL (needs routing)
 */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import { COMPONENT_LIST, INITIAL_INDEX } from './constants'

const useStyles = makeStyles(theme => ({
  designIndex: {
    display: 'flex',
    flex: 1, // fill the available space
    justifyContent: 'center', // horizontal center
    backgroundColor: theme.palette.primary.light,
    height: '100vh',
  },

  // Side and Main Panels
  sidebar: {
    backgroundColor: theme.palette.primary.lightest,
    minWidth: 200, // sidebar width
    padding: theme.spacing(4),
  },

  main: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center', // vertical center
    // marginLeft: 200, // must match sidebar width
  },

  // Side Panel Stuff
  sidebarHeader: {
    marginTop: 20,
    marginBottom: 20,
  },

  componentListContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  link: {
    cursor: 'pointer',
  },

  // Main Panel Stuff
  componentName: {
    position: 'fixed',
    top: 0,
    color: theme.palette.common.white,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  componentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: theme.palette.primary.light
  },
}))

const DesignIndex = props => {
  const classes = useStyles(props)

  const [selectedIndex, setSelectedIndex] = useState(INITIAL_INDEX)

  const handleItemClick = (event, index) => {
    setSelectedIndex(index)
  }

  const MyComponent = COMPONENT_LIST[selectedIndex].component
  const componentName = COMPONENT_LIST[selectedIndex].name

  return (
    <div className={classes.designIndex}>
      <aside className={classes.sidebar}>
        <div className={classes.componentListContainer}>
          <div className={classes.sidebarHeader}>
            <Typography variant="h6">List of Designs</Typography>
          </div>
          {COMPONENT_LIST.map((item, index) => {
            return (
              <Link key={index} className={classes.link} onClick={e => handleItemClick(e, index)}>
                {item.name}
              </Link>
            )
          })}
        </div>
      </aside>
      <main className={classes.main}>
        <div className={classes.componentName}>
          <Typography variant="h5">{componentName}</Typography>
        </div>
        <div className={classes.componentContainer}>
          <MyComponent />
        </div>
      </main>
    </div>
  )
}

export default DesignIndex
