import React, { useState, useContext } from 'react'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'

import { createAssignment } from '../../common/api'
import { storage } from '../../common/firebase'

import logoTransLandImg from '../../assets/images/logo_transparent_landscape.png'

import SearchBar from './components/SearchBar'
import LeftMenu from './components/LeftMenu'
import FileListing from './components/file-listing/FileListing'
import UploadForm from './components/UploadForm'
import Breadcrumb from './components/Breadcrumb'

import NewFolderModal from './components/new-items/NewFolderModal'

import { UserContext } from '../../providers/UserProvider'

const useStyles = makeStyles(theme => ({
  dashboard: {
    display: 'grid',
    height: '100vh',
    overflowY: 'scroll',

    // TODO: Hardcoded 20fr to "fill" because not sure of a better way
    // TODO: ... and "auto" doesn't quite do it
    // https://stackoverflow.com/questions/45870323/does-css-grid-have-a-flex-grow-function
    gridTemplate: `
      "logo search" 1fr
      "left-menu breadcrumb" 1fr
      "left-menu filelist" 20fr
      / 1fr 20fr
    `,
  },

  // Grid Items
  gridItem: {
    backgroundColor: theme.palette.common.white,
    // border: '1px solid #ff652f',
    padding: theme.spacing(1),
  },

  logoItem: {
    gridArea: 'logo',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[300],
  },

  searchItem: {
    gridArea: 'search',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[300],
  },

  leftMenuItem: {
    gridArea: 'left-menu',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },

  breadcrumbItem: {
    gridArea: 'breadcrumb',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[300],
    paddingTop: 0,
    paddingBottom: 0,
  },

  fileListItem: {
    gridArea: 'filelist',
  },

  // Logo
  logoContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 180,
  },

  logo: {
    width: '100%',
    height: 'auto',
  },

  // Dialogs
  dialog: {},

  dialogPaper: {
    display: 'flex',
    flex: 1,
    padding: theme.spacing(4),
  },
}))

const Dashboard = props => {
  const classes = useStyles(props)

  const [searchTerm, setSearchTerm] = useState('')
  const [files, setFiles] = useState([])
  const [assignment, setAssignment] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [newFolderModalOpen, setNewFolderModalOpen] = useState(false)

  const currentUser = useContext(UserContext) // must be here, not inside function

  console.log('DASHBOARD > currentUser:')
  console.log(currentUser)

  console.log('DASHBOARD > files:')
  console.log(files)

  const photoURL = currentUser?.photoURL || '/static/images/avatar/1.jpg'

  const handleNewFolderClick = () => {
    setNewFolderModalOpen(true)
  }

  const handleNewFolderClose = () => {
    setNewFolderModalOpen(false)
  }

  const handleNewAssignmentOpen = () => {
    setModalOpen(true)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleFileChange = async event => {
    console.log(event)
    const uploadfiles = event
    console.log(uploadfiles)
    setFiles(uploadfiles)

    for (var i = 0; i < uploadfiles.length; i++) {
      const file = uploadfiles[i]
      console.log('assignment: ', assignment, file)
      try {
        await uploadImageAsPromise(file).then(res => {
          console.log('res', res)
        })
      } catch (error) {
        console.log(error)
      }
    }
    handleClose()
    createAssignment(currentUser, assignment)
    setAssignment('')
  }

  const uploadImageAsPromise = async file => {
    return new Promise(function (resolve, reject) {
      var storageRef = storage.ref()
      console.log('in promise', storageRef)
      let task = storageRef.child(`${currentUser.id}/${assignment}/${file.name}`).put(file)

      //Update progress bar
      task.on(
        'state_changed',
        snapshot => {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('DASHBOARD > percentage: ' + percentage)
        },
        err => {
          console.log(err)
          reject(err)
        },
        () => {
          let downloadURL = task.snapshot.downloadURL
          resolve(downloadURL)
        }
      )
    })
  }

  return (
    <div className={classes.dashboard}>
      <div className={clsx(classes.gridItem, classes.logoItem)}>
        {/* TODO: Make this smaller, use the "right way" to do images */}
        {/* <LogoLandscape /> */}
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={logoTransLandImg} alt="codechec logo" />
        </div>
      </div>
      <div className={clsx(classes.gridItem, classes.searchItem)}>
        <SearchBar setSearchTerm={setSearchTerm} photoURL={photoURL} />
      </div>
      <div className={clsx(classes.gridItem, classes.leftMenuItem)}>
        <LeftMenu
          handleNewAssignmentOpen={handleNewAssignmentOpen}
          handleNewFolderClick={handleNewFolderClick}
        />
      </div>
      <div className={clsx(classes.gridItem, classes.breadcrumbItem)}>
        <Breadcrumb />
      </div>
      <div className={clsx(classes.gridItem, classes.fileListItem)}>
        <FileListing searchTerm={searchTerm} currentUser={currentUser} />
      </div>

      <Dialog className={classes.dialog} open={newFolderModalOpen} onClose={handleNewFolderClose}>
        <NewFolderModal handleNewFolderClose={handleNewFolderClose} />
      </Dialog>

      <Dialog
        className={classes.dialog}
        open={modalOpen}
        onClose={handleClose}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <UploadForm
          assignment={assignment}
          setAssignment={setAssignment}
          handleFileChange={handleFileChange}
        />
      </Dialog>
    </div>
  )
}

export default Dashboard
