import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { UserContext } from '../../providers/UserProvider'

import { auth } from '../firebase'

const useStyles = makeStyles(theme => ({
  button: {
    marginBottom: theme.spacing(1),
  },

  logoutButton: {
    marginLeft: theme.spacing(2),
  },
}))

const LoginButton = props => {
  const classes = useStyles(props)
  const history = useHistory()

  const user = useContext(UserContext)
  // console.log('HEADER > user:')
  // console.log(user)

  return (
    <>
      {user ? (
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={() => {
              history.replace('/dashboard') // TODO: is this the right to redirect here?
            }}
          >
            Dashboard
          </Button>
          <Button
            className={clsx(classes.button, classes.logoutButton)}
            variant="outlined"
            color="primary"
            onClick={() => {
              history.replace('/')
              auth.signOut()
            }}
          >
            Log Out
          </Button>
        </div>
      ) : (
        <Button className={classes.button} href="/login" variant="outlined" color="primary">
          Log In
        </Button>
      )}
    </>
  )
}

export default LoginButton
