import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import DescriptionIcon from '@material-ui/icons/Description'
import FolderIcon from '@material-ui/icons/Folder'

const useStyles = makeStyles(theme => ({
  newItemPanel: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minWidth: 240,
  },

  menuItem: {
    marginTop: theme.spacing(0.5),
  },

  menuItemText: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 13,
    fontWeight: 500,
  },

  listItemIcon: {
    minWidth: 48,
  },

  fileIcon: {
    color: '#4585F4',
  },
}))

const NewItemPanel = props => {
  const classes = useStyles(props)

  const { handleNewAssignmentClickAndClose, handleNewFolderClickAndClose } = props

  console.log('NEW ITEM PANEL:')
  console.log(handleNewAssignmentClickAndClose)

  return (
    <Paper className={classes.newItemPanel}>
      <MenuList>
        <MenuItem className={classes.menuItem} onClick={handleNewFolderClickAndClose}>
          <ListItemIcon
            classes={{
              root: classes.listItemIcon,
            }}
          >
            <FolderIcon className={classes.folderIcon} />
          </ListItemIcon>
          <Typography className={classes.menuItemText}>Folder</Typography>
        </MenuItem>

        <MenuItem className={classes.menuItem} onClick={handleNewAssignmentClickAndClose}>
          <ListItemIcon
            classes={{
              root: classes.listItemIcon,
            }}
          >
            <DescriptionIcon className={classes.fileIcon} />
          </ListItemIcon>
          <Typography className={classes.menuItemText}>Assignment</Typography>
        </MenuItem>
      </MenuList>
    </Paper>
  )
}

export default NewItemPanel
