import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import clsx from 'clsx'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(theme => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 250,
    padding: theme.spacing(4)
  },

  newButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 600
  },

  newButton: {
    color: 'black',
    backgroundColor: '#e37054', // light red-orange
    fontWeight: 'bold',
    fontSize: 20,
    minWidth: 100,
    textTransform: 'none' // Title Case
  },

  row: {
    display: 'flex',
    flex: 1,
    marginTop: theme.spacing(2)
  },

  field: {
    display: 'flex',
    flex: 1,
    minWidth: 600
  },

  searchField: {
    backgroundColor: '#e2e2e2' // light gray
  },

  card: {
    minWidth: 600,
    maxHeight: 55,
    backgroundColor: '#aad5f2' // light blue
  },

  pageInfoCard: {
    marginTop: theme.spacing(4)
  },

  pageInfoCardContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: 600,
    maxHeight: 50,
    backgroundColor: '#e2e2e2' // light gray
  },

  cardText: {
    fontWeight: 'bold'
  },

  cardTextRows: {
    flex: 1, // split the bottom card in 3 equal parts
    fontWeight: 'bold',
    fontSize: 12
  },

  cardTextNums: {
    display: 'flex',
    flex: 1, // split the bottom card in 3 equal parts
    fontWeight: 'bold',
    fontSize: 12,
    justifyContent: 'center'
  },

  cardTextEmpty: {
    flex: 1 // split the bottom card in 3 equal parts
  }
}))

const Listing1 = props => {
  const classes = useStyles(props)

  return (
    <div className={classes.listing}>
      <Paper className={classes.dialog}>
        <div className={classes.newButtonContainer}>
          <Button className={classes.newButton} variant="contained" color="secondary">
            New
          </Button>
        </div>
        <div className={classes.row}>
          <TextField
            className={clsx(classes.field, classes.searchField)}
            label="Search"
            type="search"
            variant="outlined"
            size="small"
            // Magnifying Glass icon
            // https://stackoverflow.com/questions/54970335/material-ui-how-to-show-search-icon-in-input-field-in-react
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={classes.row}>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.cardText}>HOMEWORK 5</Typography>
            </CardContent>
          </Card>
        </div>
        <div className={classes.row}>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.cardText}>HOMEWORK 4</Typography>
            </CardContent>
          </Card>
        </div>
        <div className={classes.row}>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.cardText}>HOMEWORK 3</Typography>
            </CardContent>
          </Card>
        </div>
        <div className={classes.row}>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.cardText}>TEST 1</Typography>
            </CardContent>
          </Card>
        </div>
        <div className={classes.row}>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.cardText}>HOMEWORK 2</Typography>
            </CardContent>
          </Card>
        </div>
        <div className={classes.row}>
          <Card className={classes.pageInfoCard}>
            <CardContent className={classes.pageInfoCardContent}>
              <Typography className={classes.cardTextRows}>Rows per page</Typography>
              <Typography className={classes.cardTextNums}>1-5 of 19</Typography>
              <Typography className={classes.cardTextEmpty}></Typography>
            </CardContent>
          </Card>
        </div>
      </Paper>
    </div>
  )
}

export default Listing1
