import CreateAccountDemo from './components/CreateAccountDemo'
import CreateAccountWarren from './components/CreateAccountWarren'
import Listing1 from './components/Listing1'
import Listing2 from './components/Listing2'
import Empty from './components/Empty'
import Whatever from './components/Whatever'
import Avatar from './components/Avatar'
import Profile from './components/Profile'

const INITIAL_INDEX = 1 // the index of the component to load initially

const COMPONENT_LIST = [
  {
    name: 'Empty',
    component: Empty,
  },
  {
    name: 'Create Account Demo',
    component: CreateAccountDemo,
  },
  {
    name: 'Create Account Warren',
    component: CreateAccountWarren,
  },
  {
    name: 'Cool Listing',
    component: Listing1,
  },
  {
    name: 'Even Cooler Listing',
    component: Listing2,
  },
  {
    name: 'Whatever Empty Template',
    component: Whatever,
  },
  {
    name: 'Avatar',
    component: Avatar,
  },
  {
    name: 'Profile',
    component: Profile,
  },
]

export { COMPONENT_LIST, INITIAL_INDEX }
