import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  headerLayout: {
    display: 'grid',
    gridTemplateRows: 'auto auto',

    width: '100vw',
    height: '100vh',
    margin: 0,
    padding: 0,
  },

  header: {},

  content: {
    overflowY: 'scroll',
  },
}))

const HeaderContentLayout = props => {
  const classes = useStyles(props)

  const { header: Header, content: Content, ...rest } = props

  return (
    <div className={classes.headerLayout}>
      <div className={classes.header}>{Header ? <Header {...rest} /> : 'HEADER MISSING'}</div>
      <div className={classes.content}>{Content ? <Content {...rest} /> : 'CONTENT MISSING'}</div>
    </div>
  )
}

export default HeaderContentLayout
