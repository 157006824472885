import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import clsx from 'clsx'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
// import useMediaQuery from '@material-ui/core/useMediaQuery'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import logoTransCropped from '../../../assets/images/logo_transparent_cropped.png'
import logoTransLandscape from '../../../assets/images/logo_transparent_landscape.png'

/**
 * STEPS:
 * - Copy Empty to your new ComponentName.jsx
 * - Rename component internally
 * - Measure out the design using dev tools inspector
 * - Determine Flex box layouts in your head
 * - Create flex boxes and rough HTML elements
 * - Position the elements using height/width/margins
 * - Add MUI elements by browsing the MUI website demos
 */

const useStyles = makeStyles(theme => ({
  paperContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',

    backgroundColor: 'lightblue',
    padding: theme.spacing(5),
    justifyContent: 'center',
    alignItems: 'center',
  },

  paper: {
    display: 'flex',
    flex: 1,

    minHeight: 526,
    minWidth: 748,
    maxHeight: 526,
    maxWidth: 748,

    paddingTop: 48,
    paddingLeft: 40,
    paddingRight: 0,
    paddingBottom: 36,
  },

  // Form on the left side of the page
  formContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minWidth: 368,
    maxWidth: 368,
  },

  logoLandscapeContainer: {
    maxWidth: 100,
  },

  logoLandscape: {
    height: 'auto',
    width: '100%',
  },

  formTitle: {
    marginTop: theme.spacing(2),
  },

  form: {
    paddingTop: theme.spacing(3),
  },

  nameFieldsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 60,
  },

  field: {},

  nameFields: {
    maxWidth: 176,
    minWidth: 176,
  },

  firstNameField: {
    marginRight: theme.spacing(2),
  },

  lastNameField: {},

  usernameContainer: {
    minHeight: 96,
    maxHeight: 96,
  },

  usernameField: {
    minWidth: '100%',
  },

  usernameHelperText: {
    marginBottom: theme.spacing(1),
  },

  currentEmailButton: {
    display: 'flex',
    padding: 0,

    textTransform: 'none',
  },

  passwordFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 24,
    paddingBottom: 8,
    maxWidth: 320,
    marginRight: 0,
  },

  passwordField: {
    minWidth: 152,
    maxWidth: 152,
    marginTop: 0,
    marginBottom: 0,
    marginRight: 16,
  },

  currentPasswordField: {
    minWidth: 152,
    maxWidth: 152,
    marginTop: 0,
    marginBottom: 0,
  },

  passwordVisibilityToggle: {
    display: 'flex',
    minHeight: 48,
    maxHeight: 48,
    minWidth: 48,
    maxWidth: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },

  passwordHelperTextContainer: {
    maxWidth: 320,
  },

  footerButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 32,
  },

  signInButton: {
    textTransform: 'none',
  },

  nextButton: {
    textTransform: 'none',
  },

  logoPotraitContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  logoPotrait: {
    minHeight: 244,
    minWidth: 244,
    maxHeight: 244,
    maxWidth: 244,
  },

  pageFooterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    maxWidth: 748,
    minWidth: 748,
  },

  languageDropdown: {
    textTransform: 'none',
    paddingTop: 24,
    maxWidth: 164.41,
  },

  helpButtonsContainer: {
    justifyContent: 'space-between',
    paddingTop: 24,
    maxWidth: 194.26,
  },

  helpButtons: {
    textTransform: 'none',
    color: 'grey',
  },
}))

const CreateAccountDemo = props => {
  const classes = useStyles(props)
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  })

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // const matches = useMediaQuery('(min-width:960px)')

  return (
    <>
      <div className={classes.paperContainer}>
        <Paper className={classes.paper} variant="outlined">
          <div className={classes.formContainer}>
            <div className={classes.logoLandscapeContainer}>
              <img className={classes.logoLandscape} src={logoTransLandscape} alt="logo" />
            </div>
            <div className={classes.formTitle}>
              <Typography variant="h5">Create your CodeChec Account</Typography>
            </div>
            <div className={classes.form}>
              <div className={classes.nameFieldsContainer}>
                <TextField
                  className={clsx(classes.field, classes.nameFields, classes.firstNameField)}
                  label="First name"
                  variant="outlined"
                  margin="dense"
                  size="small"
                />
                <TextField
                  className={clsx(classes.field, classes.nameFields, classes.lastNameField)}
                  label="Last name"
                  variant="outlined"
                  margin="dense"
                  size="small"
                />
              </div>
              <div className={classes.usernameContainer}>
                <TextField
                  className={clsx(classes.field, classes.usernameField)}
                  label="Username"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">@CodeChec</InputAdornment>,
                  }}
                ></TextField>
                <Typography className={classes.usernameHelperText} variant="caption">
                  You can use letters, numbers &amp; periods
                </Typography>

                <Button className={classes.currentEmailButton} color="primary">
                  Use my current email address instead
                </Button>
              </div>

              <div className={classes.passwordFieldContainer}>
                <TextField
                  className={clsx(classes.field, classes.passwordField)}
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  label="Password"
                  variant="outlined"
                  margin="dense"
                  size="small"
                />
                <TextField
                  className={clsx(classes.field, classes.currentPasswordField)}
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.repeatPassword}
                  onChange={handleChange('repeatPassword')}
                  label="Confirm"
                  variant="outlined"
                  margin="dense"
                  size="small"
                />
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </div>
              <div className={classes.passwordHelperTextContainer}>
                <Typography
                  variant="caption"
                  classes={{
                    caption: classes.test,
                  }}
                >
                  Use 8 or more characters with a mix of letters, numbers &amp; symbols
                </Typography>
              </div>
              <div className={classes.footerButtonContainer}>
                <Button className={classes.signInButton} color="primary">
                  Sign in instead
                </Button>
                <Button
                  className={classes.nextButton}
                  variant="contained"
                  color="primary"
                  disableElevation="true"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
          <div className={classes.logoPotraitContainer}>
            <img className={classes.logoPotrait} src={logoTransCropped} alt="logo" />
          </div>
        </Paper>
        <div className={classes.pageFooterContainer}>
          <div className={classes.languageDropdown}>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              English
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Japanese</MenuItem>
              <MenuItem onClick={handleClose}>French</MenuItem>
              <MenuItem onClick={handleClose}>Spanish</MenuItem>
            </Menu>
          </div>
          <div className={classes.helpButtonsContainer}>
            <Button className={classes.helpButtons}>Help</Button>
            <Button className={classes.helpButtons}>Privacy</Button>
            <Button className={classes.helpButtons}>Terms</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateAccountDemo
