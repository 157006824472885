import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'

import NewItemPanel from './NewItemPanel'

const useStyles = makeStyles(theme => ({
  button: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontWeight: 'bold',
    textTransform: 'capitalize',
    borderRadius: theme.spacing(3), // circular ends styling
  },

  newButtonText: {
    fontWeight: 500,
  },
}))

const NewItemButton = props => {
  const classes = useStyles(props)

  const { handleNewAssignmentClick, handleNewFolderClick } = props

  // console.log('NEW ITEM BUTTON:')
  // console.log(rest)

  const [anchor, setAnchor] = useState(null)

  const handleNewFolderClickAndClose = () => {
    console.log('NEW ITEM BUTTON > HANDLE NEW FOLDER CLICK AND CLOSE')
    handleNewFolderClick()
    handleClose()
  }

  const handleNewAssignmentClickAndClose = () => {
    handleNewAssignmentClick()
    handleClose()
  }

  const handleClick = event => {
    setAnchor(event.currentTarget) // visually anchor the popup to the button
  }

  const handleClose = () => setAnchor(null)

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        startIcon={<AddIcon />}
        size="large"
        onClick={handleClick}
      >
        <Typography className={classes.newButtonText} noWrap>
          New
        </Typography>
      </Button>

      <Popover
        classes={{
          paper: classes.popoverPaper,
        }}
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        anchorOrigin={{
          // Location of the anchor on the button
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          // Anchor on the popover itself
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <NewItemPanel
          handleNewFolderClickAndClose={handleNewFolderClickAndClose}
          handleNewAssignmentClickAndClose={handleNewAssignmentClickAndClose}
        />
      </Popover>
    </>
  )
}

export default NewItemButton
