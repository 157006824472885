// Firebase Console > Project Overview > Add App > Web App
// OR
// Firebase Console > Project Settings > General > Your apps

// OK to push to repo because it would be public in HTML source anyway
export const firebaseConfig = {
  apiKey: 'AIzaSyAnipdm56sKXtgdBhkOxOLjSPXqgmBaBuc',
  authDomain: 'code-plagiarism-checker.firebaseapp.com',
  databaseURL: 'https://code-plagiarism-checker.firebaseio.com',
  projectId: 'code-plagiarism-checker',
  storageBucket: 'code-plagiarism-checker.appspot.com',
  messagingSenderId: '12334256147',
  appId: '1:12334256147:web:512e90986854bd5108afe6',
  measurementId: 'G-P1LK45PG3E'
}
